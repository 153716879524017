@import-normalize;

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/montserrat-v15-latin-300.eot');
  src: local(''),
    url('assets/fonts/montserrat-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/montserrat-v15-latin-300.woff2') format('woff2'),
    url('assets/fonts/montserrat-v15-latin-300.woff') format('woff'),
    url('assets/fonts/montserrat-v15-latin-300.ttf') format('truetype'),
    url('assets/fonts/montserrat-v15-latin-300.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/montserrat-v15-latin-regular.eot');
  src: local(''),
    url('assets/fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/montserrat-v15-latin-regular.woff2') format('woff2'),
    url('assets/fonts/montserrat-v15-latin-regular.woff') format('woff'),
    url('assets/fonts/montserrat-v15-latin-regular.ttf') format('truetype'),
    url('assets/fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/montserrat-v15-latin-500.eot');
  src: local(''),
    url('assets/fonts/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/montserrat-v15-latin-500.woff2') format('woff2'),
    url('assets/fonts/montserrat-v15-latin-500.woff') format('woff'),
    url('assets/fonts/montserrat-v15-latin-500.ttf') format('truetype'),
    url('assets/fonts/montserrat-v15-latin-500.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/montserrat-v15-latin-700.eot');
  src: local(''),
    url('assets/fonts/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/montserrat-v15-latin-700.woff2') format('woff2'),
    url('assets/fonts/montserrat-v15-latin-700.woff') format('woff'),
    url('assets/fonts/montserrat-v15-latin-700.ttf') format('truetype'),
    url('assets/fonts/montserrat-v15-latin-700.svg#Montserrat') format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/montserrat-v15-latin-800.eot');
  src: local(''),
    url('assets/fonts/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/montserrat-v15-latin-800.woff2') format('woff2'),
    url('assets/fonts/montserrat-v15-latin-800.woff') format('woff'),
    url('assets/fonts/montserrat-v15-latin-800.ttf') format('truetype'),
    url('assets/fonts/montserrat-v15-latin-800.svg#Montserrat') format('svg');
}

html, body {
  background-color: #000000;
  color: #f2ebe3;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  overscroll-behavior: none;
}

html, a, nav, button {
  cursor: none !important;
  font-family: 'Montserrat', sans-serif;
}

body {
  overflow: hidden;
}

body.light-mode {
  overflow-y: scroll;
}

a, a:active, a:hover {
  color: inherit;
  text-decoration: none;
}

.white {
  color: #f2ebe3;
}

ul, li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.row img {
  width: 100%;
  height: auto;
  max-height: 30%;
}

.divider {
  background: #f2ebe3;
  height: 5px;
  width: 100px;
  display: inline-block;
}

p {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8em;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

p.intro {
  font-size: 18px;
  font-weight: 600;
}

p.small {
  font-size: 0.6em;
  opacity: 0.6;
}

h1, h2 {
  line-height: 1em;
  margin: 0;
  padding: 0;
  font-size: 4em;
  font-weight: 700;
}

h1.clear {
  color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  margin-top: -20px;
}

h1 span, h2 span {
  -webkit-animation: glow 6s ease-in-out infinite alternate;
  -moz-animation: glow 6s ease-in-out infinite alternate;
  animation: glow 6s ease-in-out infinite alternate;
}

h3 {
  text-transform: uppercase;
  font-size: 0.9em;
}

.sborder {
  background:
    linear-gradient(to right, rgb(255, 255, 255) 4px, transparent 4px) 0 0,
    linear-gradient(to right, rgb(255, 255, 255) 4px, transparent 4px) 0 100%,
    linear-gradient(to left, rgb(255, 255, 255) 4px, transparent 4px) 100% 0,
    linear-gradient(to left, rgb(255, 255, 255) 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, rgb(255, 255, 255) 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, rgb(255, 255, 255) 4px, transparent 4px) 100% 0,
    linear-gradient(to top, rgb(255, 255, 255) 4px, transparent 4px) 0 100%,
    linear-gradient(to top, rgb(255, 255, 255) 4px, transparent 4px) 100% 100%;
  display: grid;
  padding: 40px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.subline {
  font-size: 0.6em;
}

figure {
  margin: 0;
}

figcaption {
  margin-top: 10px;
  font-size: 0.8em;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #f2ebe3;
    color: rgba(255, 255, 255, 1.0);
  }

  to {
    text-shadow: 0 0 15px #f2ebe3;
    color: rgba(255, 255, 255, 0.875);
  }
}

@keyframes glowbox {
  from {
    box-shadow: 0 0 1px #f2ebe39c;
  }

  to {
    box-shadow: 0 0 25px #f2ebe3;
  }
}

.particles {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f2ebe3;
  z-index: 999;
  position: absolute;
  width: 85%;
  text-align: left;
  margin-left: 10%;
}

.App-header.true {
  display: none
}

.magnet {
  display: inline-block;
  color: black;
  border-radius: 50px;
  font-weight: bold;
  background-color: #f2ebe3;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all .25s ease;
  border: 2px solid #f2ebe3;
  outline: none;
  text-transform: uppercase;
  margin-top: 30px;
  padding: 20px 30px;
  min-width: 180px;
  text-align: left;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  margin-left: 10px;
  letter-spacing: 0.1em;
  -webkit-animation: glowbox 1s ease-in-out infinite alternate;
  -moz-animation: glowbox 1s ease-in-out infinite alternate;
  animation: glowbox 1s ease-in-out infinite alternate;
}

.magnet-mouse-active {
  background-color: #f2ebe3;
  color: #000000;
}

.magnet::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 14px;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid;
  right: 30px;
  transition: right 0.2s ease;
  margin-top: 3px;
}

h3 a:hover,
.magnet:hover {
  opacity: 0.85;
}

.magnet:hover::before {
  right: 25px
}

.menu {
  top: 20px;
  left: 20px;
  color: #f2ebe3;
  position: fixed;
  z-index: 9999;
  padding: 21px 19px;
  border-radius: 100%;
  display: block;
  border: 2px solid transparent;
  transition: all 1s ease;
  opacity: 0;
}

.clicked .menu {
  opacity: 1.0;
}

.menu:hover {
  border: 2px solid #f2ebe3;
}

.logo {
  position: fixed;
  right: 10px;
  top: 150px;
  z-index: 999;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.logo a {
  display: inline-block;
}

.clicked .logo {
  opacity: 0.5;
}

.logo:hover {
  opacity: 1;
}

.logo.open {
  filter: invert(1);
  opacity: 1.0;
}

/* Title IN Animation*/
.drawing header .divider,
.drawing header .subline,
.drawing header h1,
.drawing header h3 {
  opacity: 0;
}

.drawing.clicked header .divider,
.drawing.clicked header .subline,
.drawing.clicked header h1,
.drawing.clicked header h3 {
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}

.drawing.clicked header h3 {
  animation-duration: 2s;
  animation-delay: 2s;
}

.drawing.clicked header h1 {
  animation-duration: 2s;
  animation-delay: 4s;
}

.drawing.clicked header .divider,
.drawing.clicked header .subline {
  animation-duration: 1s;
  animation-delay: 6s;
}

.startbtn {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.clicked .startbtn {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 7s;
}

.clicked .startbtn.true {
  animation: fadeOut 1s;
  opacity: 0;
}

/* Icon 3 */
#nav-icon3 {
  width: 28px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #f2ebe3;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon3.open {
  filter: invert(1);
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2), #nav-icon3 span:nth-child(3) {
  top: 10px;
}

#nav-icon3 span:nth-child(4) {
  top: 20px;
}

#nav-icon3.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/*Overlay*/
#overlay {
  position: fixed;
  background: #f2ebe3;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: none;
  opacity: 0.1;
  transition: opacity 2.55s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#overlay.open {
  display: block;
  opacity: 1.0;
}

/* Pattern styles */
.container {
  display: flex;
  height: 100%;
}

.right-half,
.left-half {
  flex: 1 1;
  margin: auto;
  padding: 0 0%;
  position: relative;
}

#overlay p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  font-size: 14px;
  margin: 0;
}

@media only screen and (min-width: 1280px) {
  #overlay p {
    font-size: 18px;
  }

  .logo img {
    width: 60px;
    height: auto;
  }
}

.mainmenu {
  font-size: 4vw;
  text-align: left;
  font-weight: 700;
  padding: 0 18%;
}

.mainmenu li {
  margin-bottom: 1vw;
  color: black;
  font-weight: 900;
  letter-spacing: 0.05em;
}

.mainmenu a {
  padding: 0 1vw;
  transition: all 0.2s ease;
  color: #000;
}

#overlay a {
  color: #000;
}

.description {
  text-align: left;
  line-height: 2em;
  padding: 0 18%;
}

.mainmenu li:hover a {
  color: #f2ebe3 !important;
  background: #000;
}

.overlayFooter {
  text-align: left;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 0.8em;
  /* padding: 5% 18% 0 18%; */
  position: fixed;
  bottom: 37px;
  left: 50px;
}

.share {
  margin-left: 20px;
  margin-bottom: -7px;
}

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
}

.cursor--small {
  width: 10px;
  height: 10px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  z-index: 11000;
  background: white;
  cursor: none !important;
  box-shadow:
    0 0 8px 8px rgba(255, 255, 255, 0.1),
    /* inner white */
    0 0 50px 25px rgba(255, 255, 255, 0.4);
  /* middle magenta
    /*0 0 70px 45px #f2ebe3; /* outer cyan */
}

.light-mode .cursor--small,
.cursor--small.open {
  filter: invert(1);
  z-index: 9999999;
}

@media (hover: none) {
  .cursor--small {
    display: none;
  }
}

@media only screen and (max-width: 940px) {
  .menu {
    top: 20px;
    left: 10px;
  }

  /* For mobile phones: */
  .logo {
    top: 20px;
  }

  .logo img {
    clip-path: polygon(0 0, 100% 0%, 100% 31%, 0% 31%);
  }

  h1, h2 {
    font-size: 2.6em;
  }

  .container {
    display: block;
    overflow-y: scroll;
  }

  .mainmenu {
    font-size: 2.4em;
    padding: 3em 1em 3em 1em;
    margin-top: 0;
    /* box-shadow: 0 0 0 10px black; */
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .mainmenu li {
    margin-bottom: 0.5em;
  }

  .description {
    text-align: left;
    padding: 3em;
    line-height: 2em;
  }

  #overlay p {
    font-size: inherit;
    margin: 0;
  }

  .btn {
    font-size: 0 !important;
  }
}

/*Drawing*/
.drawnavbar {
  position: fixed;
  bottom: 5px;
  width: calc(100% - 40px);
  padding: 20px;
  text-align: center;
}

.btn {
  border: 2px solid rgba(255, 255, 255, 1.0);
  color: #f2ebe3;
  text-transform: uppercase;
  display: inline-block;
  padding: 18px 19px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: bolder;
  opacity: 0.8;
}

.next {
  float: right;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center right;
}

.back {
  float: left;
}

.arrow {
  border: solid #f2ebe3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 10px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 10px;
}

.btn.icon {
  font-size: 0;
  line-height: 13px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid rgba(255, 255, 255, 0.75)
}

.btn.plainicon:active,
.btn.plainicon:focus,
.btn.plainicon {
  font-size: 0;
  line-height: 13px;
  display: inline-block;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.1s ease;
  padding: 28px;
}

.btn.plainicon.true {
  opacity: 1;
}

.btn.plainicon.ut {
  background-image: url('assets/icons/ut.svg');
  margin-left: 10px;
}

.btn.plainicon.mute {
  background-image: url('assets/icons/speaker.svg');
  margin-right: 10px;
}

.btn.plainicon.mute.true {
  background-image: url('assets/icons/speaker-x.svg');
}

@media (hover: hover) {
  .btn.plainicon:hover {
    opacity: 1.0;
    border: none;
    background-color: transparent;
  }

  .iconbtn:hover,
  .btn:hover {
    opacity: 1.0;
    background-color: #f2ebe3;
    color: black;
    border: 2px solid rgba(255, 255, 255, 0.575);
  }

  .iconbtn:hover .arrow,
  .btn:hover .arrow {
    border-color: black;
  }

  .btn.icon:hover .audiobutton {
    border-color: transparent transparent transparent #000;
  }
}

.next.true {
  opacity: 1.0;
  background-color: #f2ebe3;
  color: black;
  border: 2px solid rgba(255, 255, 255, 0.575);
}

.next.true .arrow {
  border-color: black;
}

@media only screen and (max-width: 940px) {
  .btn {
    font-size: 0;
  }

  .right, .left {
    margin: 3px;
  }

  .btn.startambient {
    font-size: 13px !important;
  }
}

.audiobutton {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 16px;
  border-color: transparent transparent transparent #fff;
  transition: 100ms all ease;
  cursor: pointer;
  border-style: solid;
  border-width: 8px 0 8px 14px;
}

.btn.icon.true>.audiobutton {
  border-style: double;
  border-width: 0px 0 0px 14px;
}

.progessbg {
  background: rgba(255, 255, 255, 0.175);
  height: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid black;
}

.progessbar {
  background: rgba(255, 255, 255, 0.875);
  width: 100%;
  height: 100%;
  /*animation: center-animate 80s linear infinite;*/
  transition: all 1s ease;
}

@keyframes center-animate {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.interactionCanvas {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  display: none;
  opacity: 0.0;
}

.interactionCanvas.true {
  display: block;
  opacity: 1;
  animation: fadeIn 5s;
}

.drawframe {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 200vh;
  height: 200vh;
  position: fixed;
  left: 75vh;
  top: 75vh;
  /*transition: all 0.1s ease;*/
}

.drawframe.false {
  animation: fadeIn 2s;
  opacity: 1;
}

.drawframe.true {
  animation: fadeOut 2s;
  opacity: 0;
}

.drawtitle {
  color: #f2ebe3;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 40px;
  left: 30%;
  width: 40%;
}

.drawtitle p {
  line-height: 1.4em;
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
}

.drawtitle p:last-child {
  opacity: 0.5;
}

.spotlight {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: radial-gradient(circle at center center, transparent, #000 15%);
  opacity: 1;
}

.content {
  max-width: 1440px;
  margin: auto;
  /* border: 1px solid grey; */
  box-shadow: 0 0 99px rgb(0 0 0 / 10%);
}

/* Simple Grid */
.row {
  display: flex;
  padding: 5% 10%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.row.first {
  padding-top: 15%;
}

.row-center,
.row-left-half,
.row-right-half {
  flex: 1;
}

.row blockquote,
.row p,
.row figure {
  margin: 3vw;
}

.row h3,
.row h2,
.row .divider {
  margin: 0 3vw
}

.row-thirdy {
  display: flex;
  flex-wrap: wrap;
}

.row-third {
  flex-grow: 1;
  width: 33%;
}

.mauto {
  margin: auto;
}

.seven {
  width: 70%;
}

.editor {
  font-weight: bold;
  opacity: 0.5;
}

blockquote {
  font-size: 1.6em;
  line-height: 1.4em;
  font-weight: 800;
  flex: 1;
  text-indent: -0.45em;
}

@media only screen and (max-width: 940px) {
  .row {
    display: block;
  }

  .seven {
    width: 100%;
  }
}

/*Modal*/
.message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  z-index: 999;
}

.startsound.false,
.tutorial {
  opacity: 0;
  z-index: 999;
  display: none;
}

.startsound,
.tutorial.true {
  display: block;
  animation: fadeIn 1s;
  opacity: 1;
}

.startsound {
  padding: 50px 30px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (min-width: 940px) {
  .tutorial p {
    font-size: 18px;
  }
}

.startambient {
  color: #000;
  background-color: #fff;
  opacity: 1.0;
  margin-top: 20px;
  width: 80%;
}

#preloader {
  background-image: url('assets/cd/logo_sign.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
}

#preloader>div {
  display: none;
}

/*Cursor Test*/
.cursor--small {
  z-index: 99;
}

.interactionCanvas {
  z-index: 999;
}

.light-mode {
  background: #f2ebe3;
  color: #000;
}

.light-mode h1,
.light-mode h2,
.light-mode h3,
.light-mode p {
  color: #000 !important;
}

.light-mode .divider,
.light-mode #nav-icon3 span {
  background: #000;
}

.light-mode .arrow,
.light-mode .particles,
.light-mode .logo {
  filter: invert(1);
  opacity: 1;
}

.light-mode #nav-icon3.open {
  filter: invert(0);
}

/*Scroll Aniamtaion*/
.icon-scroll {
  position: relative;
}

.icon-scroll {
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 50%;
  margin-top: -35px;
  box-shadow: inset 0 0 0 1px #000;
  border-radius: 25px;
}

.icon-scroll:before {
  position: absolute;
  left: 50%;
  content: '';
  width: 8px;
  height: 8px;
  background: #000;
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}

@keyframes scroll {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

/*Animation*/
.subtitle {
  color: #fff;
  position: fixed;
  width: 90%;
  text-align: center;
  bottom: 13%;
  margin: 0 5%;
  font-size: 18px;
}

.subtitle.true {
  display: block;
}

.subtitle.false {
  display: none;
}

/*Mobile General*/
@media only screen and (max-width: 940px) {
  p {
    font-size: 15px;
  }

  .App-header {
    margin-left: 5%;
    width: 95%;
  }

  .magnet {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 15px;
  }

  .subline {
    font-size: 15px;
  }

  .row {
    padding: 7%;
  }

  .row.first {
    padding-top: 35%
  }

  .light-mode p.intro {
    margin-top: 7%;
    font-size: 16px;
  }

  .row figure {
    margin-bottom: 7%;
    margin-top: 7%;
  }

  blockquote {
    font-size: 1.3em;
  }

  .overlayFooter {
    position: absolute;
  }

  .light-mode {
    color: #000;
  }

  .light-mode h2 {
    color: #000;
  }

  .subtitle {
    font-size: 15px;
    bottom: 15%;
  }
}

.App-header h3 {
  margin: 0;
}

.styles_content__1Phom,
.styles_wrapper__3KXDn {
  padding: 8px;
  transition: opacity 4.2s ease-in-out;
  overflow: hidden !important;
  opacity: 1;
}

.styles_wrapper__3KXDn {
  padding: 0;
}

.styles_wrapper__3KXDn[style*="hidden"] {
  opacity: 0;
}
